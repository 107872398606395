import React, { useEffect, useReducer, useState } from 'react'
import classes from "./Weapons.module.css"
import bullet from '../../Views/Home/bullet_black.png'
import axios from "axios"
import {Helmet} from "react-helmet";

export default function Weapons() {
    const [_, forceUpdate] = useReducer(x => x + 1, 0);
    const [forceRender, setForceRender] = useState(false);
    const [weps,setWeps] = useState([]);

    const getPublicWeapons = async () => {
        const response = await axios.get(
        `/api/v1/public-weapons`
        ).catch(e => console.log(e));
        const d = response.data;
        const newWeps = [{
            name : "Assault Riffle",
            bullets:4,
            weapons: d.filter(x => x.weaponType === "ASSAULT RIFFLES"),
            selected: 0
        },{
            name : "Battle Rifles",
            bullets:6,
            weapons: d.filter(x => x.weaponType === "BATTLE RIFLES"),
            selected: 0
        },
        {
            name : "Submachine Guns",
            bullets:3,
            weapons: d.filter(x => x.weaponType === "SUBMACHINE GUNS"),
            selected: 0
        },
        {
            name : "Riffles",
            bullets:3,
            weapons: d.filter(x => x.weaponType === "RIFFLES"),
            selected: 0
        },
        {
            name : "Shotguns",
            bullets:2,
            weapons: d.filter(x => x.weaponType === "SHOTGUNS"),
            selected: 0
        },
        {
            name : "Machine guns",
            bullets:4,
            weapons: d.filter(x => x.weaponType === "MACHINE GUNS"),
            selected: 0
        }
    ]

        setWeps(newWeps);
    }

    useEffect(() => {
      getPublicWeapons();
    }, []);

    const handleClick = (index,way) => {
        const wepsClone = weps;
        const wep = wepsClone[index];

        if(way === 'left'){
            const hasLeft = wep.selected > 0;
            if(hasLeft){
                wep.selected = wep.selected - 1;
            }
        }   
        if(way === 'right'){
            const hasRight = wep.selected < wep.weapons.length;
            if(hasRight){
                wep.selected = wep.selected + 1;
            }
        }
        wepsClone[index] = wep
        setWeps(wepsClone)
        forceUpdate()
    }

  return (
    <div className={[classes.weapons,'row'].join(' ')}>
        <Helmet>
            <title>Top Gun Budapest - Weapon selection</title>
            <meta name="description" content="Book your slot at Top Gun shooting range and choose from our wide selection of firearms. Whether you're a beginner or an advanced shooter, we have the right gun for you." />
            <meta property="og:title" content="Top Gun Budapest - Weapon selection" />
            {(()=>{
                let keywords = "Budapest shoot,Shoot in budapest,Budapest gun,Budapest fire,Topgun Shooting,Budapest program,Budapest tourist,Top Gun,Topgun,Shooting range,budapest gun range,budapest gun club,Budapest shooting range,battle riffle,weapons,Shotgun,Battle riffles,Budapest shoot,Budapest shooting range,shooting competitions,firearm rental Budapest,tactical shooting,target shooting,sport shooting,shooting events in Budapest"
                weps.map(({weapons}) => {
                    weapons.map(weapon => {
                        keywords += `,${weapon.name}`
                    })
                })
                return <meta name="keywords" content={keywords} />
            })()}
            <meta property="og:description" content="Book your slot at Top Gun shooting range in Budapest and choose from a variety of firearm types, including handguns, rifles, and more." />
            <meta property="og:type" content="website" />
            <link rel="canonical" href="https://www.budapestshootingrange.hu/weapons" />
            <meta property="og:image" content="https://www.budapestshootingrange.hu/thumbnail.png" />
        </Helmet>

        {
            weps.map((wep,index)=> {
                return <div key={wep.name} className={[classes.weaponWrap,'col-12'].join(' ')}>
                <div className="d-flex flex-md-row flex-column justify-content-between">
                    <h2>{wep.name}</h2>
                    <div className={classes.controllers}>
                        {
                          wep.weapons[wep.selected-1] && <i onClick={()=> handleClick(index,'left')} className={["bi","bi-chevron-left",classes.bal].join(" ")}></i>
                        }
                        {
                          wep.weapons[wep.selected+1] && <i onClick={()=> handleClick(index,'right')} className={["bi","bi-chevron-right",classes.jobb].join(" ")}></i>
                        }
                    </div>
                    <div className="d-flex flex-row gap-2">
                    {
                        Array.from({length: wep.bullets}).map((x,i) => {
                            return <img key={i} width="40px" height="40px" className={classes.bullets} src={bullet} alt="golyo" />
                        })
                    }
                    </div>
                </div>
                <div className="row flex-row justify-start m-0 p-0">
                    <div className="col-8 p-0">
                        <hr className={classes.hrBlue} />
                    </div>
                    <div className="col-4 p-0">
                        <hr className={classes.hrRed} />
                    </div>
                </div>
                <div className='mt-4'>
                    {
                        wep && wep.weapons.length > 0 && <div className={[classes.wepCard,classes.wepCol].join(' ')}>
                        <div className="d-flex row flex-row">
                            <div className="col-12 col-md-8">
                                <img
                                  className={classes.weapon}
                                  src={wep.weapons[wep.selected].weaponImgSmall}
                                  data-fullsize={wep.weapons[wep.selected].weaponImg}
                                  width="100%"
                                  alt="weapon_img"
                                  onLoad={(e) => {
                                      const imgElement = e.target;
                                      const fullSizeSrc = imgElement.getAttribute('data-fullsize');
                                      const fullSizeImg = new Image();

                                      fullSizeImg.src = fullSizeSrc;
                                      fullSizeImg.onload = () => {
                                          imgElement.src = fullSizeSrc;
                                      };
                                  }}
                                />
                            </div>
                            <div className="col-12 col-md-4 pt-0 p-md-5">
                                <h2>{wep.weapons[wep.selected].name}</h2>
                                <p><b>Caliber:</b> {wep.weapons[wep.selected].caliber} <br /> <b>In service:</b> {wep.weapons[wep.selected].inService}</p>
                            </div>
                        </div>
                        <div>
                            <p className="pt-3">{wep.weapons[wep.selected].description}</p>
                        </div>
                    </div>
                    }
                </div>
                </div>
            })
        }
    </div>
  )
}
