import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from 'react-router-dom'
import axios from "axios"

axios.defaults.baseURL = process.env.REACT_APP_BACKEND_URI;
axios.defaults.headers.common['App-Instance'] = process.env.REACT_APP_INSTANCE;

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <BrowserRouter> 
  <React.StrictMode>
  <App />
  </React.StrictMode>
  </BrowserRouter>
);
reportWebVitals();
