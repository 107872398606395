import React, { useState } from 'react'
import classes from "./Booking.module.css"
import moment from "moment"
import { useParams } from 'react-router-dom';
import { useEffect } from 'react';
import axios from 'axios'

export default function Booking() 
{
    //@TODO NUM-5
    const { id } = useParams();
    const [booking,setBooking] = useState(null)
    const [error,setError] = useState(null)

    
    const fetchBooking = async (orderNumber) => {
        try {
                    const response = await axios.get("/api/v1/booking/"+orderNumber);
                    if(response && response.data){
                        setBooking(response.data)
                    }
                  } catch (error) {
                    setError(error?.response?.data?.error)
                    console.log(error?.response?.data?.error)
                }
    }

      useEffect(() => {
        const responseCode = new URL(window.location).searchParams.get('r')
        const signature = new URL(window.location).searchParams.get('s')
        
        if(responseCode){
            async function handleResponseCode(){
                axios.post('/api/v1/handle-response-code',{code: responseCode, signature})
                .then(resp => {
                    const booking = resp.data
                    console.log(booking.booking)
                    setBooking(booking.booking)
                }).catch(err => {
                    setError(err.response.data)
                })
            }
            handleResponseCode()
        }else{
            fetchBooking(id)
        }
      }, [])
      
      function getPackageList(){
        const packages = booking?.selected?.map(x => {
            return x.name+' (x'+x.quantity+')'
        })
        return <span>{packages?.join(', ')}</span>
      }

  return (
    <div>
    {
        booking && <h1 className={classes.confirmed}>Booking confirmed!</h1>
    }
    <div className={classes.Booking}>
            {
                error ?  <p className={classes.fourhundredfour}>
                    <span dangerouslySetInnerHTML={{ __html: error }}></span>
                    </p>
                :
                <div className={classes.BookingCard}>
                <div className={classes.BookingCardHeader}>
                    <h1>Booking</h1>
                    <span>#{booking?.orderNumber}</span>
                </div>
                <div className={classes.BookingCardBody}>
                    <p className="greeting">Dear {booking?.firstName} {booking?.lastName},</p>
                    <p className="message">Thank you for your booking. Your booking has been confirmed for {moment(booking?.date).format("YYYY-MM-DD")} at {booking?.time}.</p>
                    <div className={classes.BookingDetails}>
                        <h3>Booking Details:</h3>
                        <div>
                            <p><strong>Simplepay transaction id: {booking?.simplepayTransactionId}</strong></p>
                            <p><strong>Status:</strong> {booking?.status}</p>
                            <p><strong>Name:</strong> {booking?.firstName} {booking?.lastName}</p>
                            <p><strong>Email:</strong> {booking?.email}</p>
                            <p><strong>Phone Number:</strong> {booking?.phoneNumber}</p>
                            <p><strong>Address:</strong> {booking?.streetAddress}, {booking?.postCode}, {booking?.city}, {booking?.country}</p>
                            <hr className="details-hr" />
                            <p><strong>Number of People:</strong> {booking?.howManyPeople}</p>
                            <p className="package-selected"><strong className="package-text">Selected Packages:</strong> {getPackageList()}</p>
                            <p><strong>Date:</strong> {moment(booking?.date).format("YYYY-MM-DD")} {booking?.time}:00</p>
                            <p><strong>Message:</strong> {booking?.message}</p>
                        </div>
                </div>
                </div>
            </div>
            }
        </div>
    </div>
  )
}
