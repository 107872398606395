import React, { useState } from "react";
import classes from "./Contact.module.css";
import axios from "axios"
import { useAlert } from 'react-alert'

export default function Contact() {
  const initFormData = {
    fullName: "",
    email: "",
    message: "",
  }
  const [formData, setFormData] = useState(initFormData);
  const alert = useAlert()

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "/api/v1/contact",
        formData
      );
      if(response.status === 200){
        alert.show('Your message has been successfully sent. Thank you for reaching out to us! We will get back to you as soon as possible.')
        setFormData(initFormData)
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div className={classes.contact}>
      <h1>Contact</h1>
      <div className="row p-4">
        <div className={["col-12", "col-md-4", classes.bcard].join(" ")}>
          <div className={classes.content}>
            <h2>Veneue</h2>
            <hr />
            <div className="d-flex flex-row gap-2 align-items-center justify-content-center">
              <i className="bi bi-geo-fill"></i>
              <a
                target="_blank"
                rel="noreferrer"
                href="https://www.google.com/maps/place/Budapest,+Tomori+u.+33,+1138/data=!4m2!3m1!1s0x4741dbcc90985553:0x3d76d0058117c5ab?sa=X&ved=2ahUKEwiB94n4jc7_AhUDhv0HHdm-B_8Q8gF6BAgQEAI"
              >
                1138 Budapest, Tomori utca 33.
              </a>
            </div>
            <h2 className="m-2">Opening hours</h2>
            <div className="d-flex gap-1 flex-column">
              <p>
                <b>Monday - Sunday:</b> 10<sup>00</sup>-18<sup>00</sup>
              </p>
            </div>
            <hr />
            <div className="d-flex flex-column gap-1 justify-content-center align-items-center mt-2">
              <p>
                <i className="bi bi-telephone-fill"></i> +36306341565
              </p>
              <p>
                <i className="bi bi-envelope"></i> info@budapestshootingrange.hu
              </p>
            </div>
            <div className="d-none d-flex flex-row gap-3 justify-content-center align-items-center mt-2">
              <a>
                <i className="bi bi-facebook"></i>
              </a>
              <a>
                <i className="bi bi-instagram"></i>
              </a>
              <a>
                <i className="bi bi-youtube"></i>
              </a>
            </div>
          </div>
        </div>
        <div className={["col-12", "col-md-4", classes.bcard].join(" ")}>
          <div className={classes.content}>
            <iframe
              title="Address"
              height="100%"
              width="100%"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d43108.83684291716!2d19.017232765426073!3d47.522977269744466!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x4741dbcc90985553%3A0x3d76d0058117c5ab!2sBudapest%2C%20Tomori%20u.%2033%2C%201138!5e0!3m2!1shu!2shu!4v1687134180515!5m2!1shu!2shu"
              loading="lazy"
            ></iframe>
          </div>
        </div>
        <div className={["col-md-4", "col-12", classes.bcard].join(" ")}>
          <div className={classes.content}>
            <h2>Questions?</h2>
            <p>Leave us a message</p>
            <form>
              <div className="row">
                <div className="col-12">
                  <input
                    type="text"
                    name="fullName"
                    placeholder="Full Name"
                    value={formData.fullName}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <input
                    type="email"
                    name="email"
                    placeholder="Email"
                    value={formData.email}
                    onChange={handleChange}
                  />
                </div>
                <div className="col-12">
                  <textarea
                    name="message"
                    placeholder="Type your message here"
                    rows="4"
                    value={formData.message}
                    onChange={handleChange}
                  />
                </div>
                <input onClick={handleSubmit} type="button" className="m-auto" value="Submit" />
              </div>
            </form>
          </div>
        </div>
      </div>
      <div className="mt-5">
        <div className="d-flex flex-row gap-4 justify-content-center align-items-center">
          <a href="/policy">Privacy Policy</a>{" "}
          <a href="/terms-and-conditions">Terms and conditions</a>{" "}
          <a href="/returns-policy">Returns Policy</a>
        </div>
        <p className={classes.footerText}>
          {" "}
          © 2020 - 2023 Top Gun Shooting range Budapest
        </p>
      </div>
    </div>
  );
}
